<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en" page="ios" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #224952 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/ios.webp" alt="GB WhatsApp iOS" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp iOS
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.20</strong> | Size: <strong>73MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      In an exciting update for iOS users, GBWhatsApp has officially released a version compatible with iPhone and iPad. Known for its advanced customization and privacy options, GBWhatsApp iOS 2024 offers features that go beyond the standard WhatsApp.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">Download GBWhatsApp iOS
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> Is GBWhatsApp iOS available? </a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> How to download and install GBWhatsApp iOS 2024?</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Features of GBWhatsApp iOS 2024</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Final Thoughts</a>
                      </li>
                    </ul>
                  </section>

                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0"> Is GBWhatsApp iOS Available?
                  </span>
                </h2>
                <p>
                  Yes, GBWhatsApp iOS is now available! The developers have launched an iOS-compatible version of this popular WhatsApp mod, bringing enhanced functionality directly to iPhone and iPad users. GBWhatsApp Pro iOS 2024 allows users to enjoy unique customization and privacy options that are typically exclusive to Android mods. You can install it on your iOS device and start exploring its advanced features today.
                </p>

                <!-- <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Download GBWhatsApp New</a>
                    </div>
                  </div> -->

                <h2 class="h">
                  <span id="TBC_1">How to Download and Install GBWhatsApp iOS 2024</span>
                </h2>
                <p>
                  To download GBWhatsApp iOS 2024 on your iPhone or iPad, follow these steps:
                </p>

                <ol>
                  <li><strong>1. Open Safari: </strong>On your iPhone or iPad, open Safari or your preferred web browser.</li>
                  <li><strong>2. Search for GBWhatsApp iOS site: </strong>Type "GB WhatsApp" into the search bar, and locate the official download page.</li>
                  <li><strong>3. Download the App: </strong>Once on the site, click on the Download button to begin the installation process.</li>
                  <li><strong>4. Allow Installation Permissions: </strong>When prompted, give permission to install the app. Since it's a third-party app, you may need to go to Settings > General > Profiles & Device Management and trust the app profile.</li>
                  <li><strong>5. Verify and Log In: </strong>After installation, open GBWhatsApp iOS, log in, and verify your phone number just like on standard WhatsApp.</li>
                </ol>

                <div class="gap"></div>
                <p>
                  Once you've logged in, GBWhatsApp iOS is ready to use! Enjoy exploring the new features that make this version unique.
                </p>

                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/ios-1.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/ios-1.webp" alt="gbwhatsapp ios" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_2">Features of GBWhatsApp iOS 2024</span>
                </h2>
                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Stickers and Emojis</h3>
                    <p>
                      Access a larger selection of custom stickers and emojis to personalize conversations and make chats more expressive.
                    </p>

                    <h3 class="h3">Send Large Files</h3>
                    <p>
                      GBWhatsApp iOS removes file-size limits, enabling you to send larger videos, photos, and documents without compression, maintaining their quality.
                    </p>

                    <h3 class="h3">High-Resolution Image Sharing</h3>
                    <p>
                      Share high-resolution images without losing quality. GBWhatsApp iOS ensures that your media files are sent in full clarity.
                    </p>

                    <h3 class="h3">Anti-Deleted Messages</h3>
                    <p>
                      Read messages even after the sender deletes them, giving you access to all conversation details.
                    </p>

                    <h3 class="h3">Change Themes</h3>
                    <p>
                      Customize the look of your app with a variety of themes, allowing you to select different color schemes and styles.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Hide Blue Ticks</h3>
                    <p>
                      Maintain privacy by hiding blue ticks, so you can read messages without notifying the sender.
                    </p>

                    <h3 class="h3">App Lock</h3>
                    <p>
                      Add an extra layer of security by locking GBWhatsApp with a password, Face ID, or Touch ID.
                    </p>

                    <h3 class="h3">Auto-Reply Messages</h3>
                    <p>
                      Use auto-reply options to respond automatically to messages, helpful when you're busy or away.
                    </p>

                    <h3 class="h3">Custom Notification Settings</h3>
                    <p>
                      Set custom notifications for specific contacts, making it easier to distinguish between messages from different contacts.
                    </p>

                    <h3 class="h3">Advanced Privacy Settings</h3>
                    <p>
                      Control your online visibility by hiding your online status, last seen, and other activity indicators.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_3"> Final Thoughts
                  </span>
                </h2>
                <p>
                  GBWhatsApp Pro iOS 2024 is an excellent option for iPhone and iPad users who want more control over their messaging experience. With features that improve privacy, media sharing, and customization, GBWhatsApp offers a refreshing alternative to the standard WhatsApp. Just remember to download it from a trusted source and follow the installation steps carefully.
                </p>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="comment-box">
    <div class="title">
      Leave a Comment
    </div>
    <input v-model="comment.name" type="text" class="nameClass" placeholder="your name">
    <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
    <textarea v-model="comment.content" class="contentClass" />
    <button class="submitClass" @click="submitClick">
        Submit
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button>
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
        lang: 'en',
        val: '🇺🇸 English'
      }],
    };
  },
  computed: {
    allCommentList() {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
    showText() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList() {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick() {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    submitClick() {
      this.$Dialog
        .confirm({
          title: 'Tip',
          message: 'Your comment has been submitted successfully and will be displayed below after review.',
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
        })
        .then(() => {
          this.comment.name = '';
          this.comment.email = '';
          this.comment.content = '';
        })
        .catch(() => {
          console.log('取消');
        });
    },
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
